<template>
  <list-container-widget
    id="faqs"
    :app-id="app.id"
    :size="getSize('faqs')"
    stretch
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="faqs" />
    </template>
    <app-scroll :options="{ scrollPanel: { scrollingX: false } }">
      <faq-accordion :searchValue="searchValue"/>
    </app-scroll>
  </list-container-widget>
</template>

<script>
import AppScroll from "@core/components/app-scroll/AppScroll.vue";
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import FaqAccordion from "@/views/apps/faqs/components/FaqAccordion.vue";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
// import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import FormTranslationTable from "@core/components/form-translation-table/FormTranslationTable.vue";

export default {
  name: "FaqAccordionWidget",

  components: {
    AppScroll,
    ListContainerWidget,
    FaqAccordion,
    FormTranslationTable,    
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },

  mixins: [WidgetLayoutMixin],

  data() {
    return {
      question: {},
      answer: {},
      searchValue: "",
    };
  },

  computed: {
    hasQuestion() {
      return Object.values(this.question).filter(item=> {
        if (item != '') {
          return item
        }
      }).length > 0;
    },
    hasAnswer() {
      return Object.values(this.answer).filter(item=> {
        if (item != '') {
          return item
        }
      }).length > 0;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
  },
  methods: {
    resetForm() {
      this.question = {};
      this.answer = {};
      this.isEdition = false;
    },
    async saveFaq() {
      if (this.hasQuestion && this.hasAnswer) {
        const settings = {
          itemType: "faqs",
          customName: "faqs",
          requestConfig: {
            question: this.question,
            answer: this.answer,
          },
        };
        try {
          await this.$store.dispatch("createItem", {
            item: settings,
          });
          this.notifySuccess(this.$t("backoffice.faqs.messages.success"));
        } catch {
          this.notifyError(this.$t("backoffice.faqs.messages.error"));
        }
      } else {
        this.notifyError(this.$t("backoffice.faqs.messages.error"));
      }
    },
    searchItems(value) {
      this.searchValue = value;
    }
  },
};
</script>
